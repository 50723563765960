import React, { Component, createRef } from "react";
import Leaflet from "leaflet";
import PropTypes from "prop-types";
import "leaflet-extra-markers";
import { connect } from "react-redux";
import { updateZoom, recenterEnd } from "../../containers/App/actions";
import { debounce } from "lodash";
// import MaskLayer from "./MaskLayer";
import Freguesias from "./Freguesias";
import Parks from "./Parks";
import Elements from "./Elements";
import Growers from "./Growers";
import EditElements from "./EditElements";
import AddElements from "./AddElements";
import { getGrowerByParcel } from "../../containers/User/actions";
import { getElementsRelation } from "../../containers/Georeference/actions";

import { Map, CircleMarker, TileLayer } from "react-leaflet";
// import { GoogleLayer } from 'react-leaflet-google';

import {
  setActivePark,
  toggleTrackUser,
  setSearch,
} from "../../containers/App/actions";
import { userLocation, centereddOnUser } from "../../containers/User/actions";
import "../../../node_modules/leaflet-geosearch/assets/css/leaflet.css";
import "../../../node_modules/leaflet/dist/leaflet.css";
import "../../../node_modules/leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
import marker from "../../images/marker-icon.png";
import markerShaddow from "../../images/marker-shadow.png";
import { MapContainer } from "./styled";
import {
  getGeoJson,
  getGeoElementJson,
} from "../../containers/ParkList/Selector";

delete Leaflet.Icon.Default.prototype._getIconUrl;
Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: marker,
  iconUrl: marker,
  shadowUrl: markerShaddow,
});

class MapComponent extends Component {
  constructor(props) {
    super(props);
    // this.pointToLayer = this.pointToLayer.bind(this);
    this.stopUserLocation = this.stopUserLocation.bind(this);
    this.startUserLocation = this.startUserLocation.bind(this);
    this.onClickMarker = this.onClickMarker.bind(this);
    this.setActivePark = this.setActivePark.bind(this);
    this.onClickFreguesia = this.onClickFreguesia.bind(this);
    this.finishedRemovingElements = this.finishedRemovingElements.bind(this);
    // this.setTotalTooltip = this.setTotalTooltip.bind(this);
    // this.delTotalTooltip = this.delTotalTooltip.bind(this);
    this.updateZoom = debounce(this.updateZoom, 500);

    this.Interval = "";
    this.state = {
      zoom: 14,
      overlay: null,
      geoJson: props.getGeoJson,
      updateElements: false,
      // totalTooltips: [],
    };
    this.userMarkerParams = {
      radius: 15,
      fillColor: "#5fba7d",
      color: "#5fba7d",
      weight: 3,
      opacity: 1,
      fillOpacity: 0.8,
      riseOnHover: true,
      autoPan: true,
      keepInView: true,
    };

    this.mapRef = createRef();

    window.selectParcel = (parkId, local, freguesia, element) => {
      this.setActivePark(parkId, local, freguesia, element);
    };

    // provider.search({ query: 'lisboa' }).then(results => {
    //   console.log(results);
    // });
  }

  setActivePark(park, type) {
    /// issue with EV element select
    this.props.setActivePark(
      park.properties.numero || this.props.numero,
      park.properties.local || this.props.local,
      park.properties.freguesia || this.props.freguesia,
      type === "element" ? park.properties.origin || park.properties.id : null
    );
  }

  onClickFreguesia(freguesia) {
    if (!freguesia) return null;
    this.props.setSearch({
      element: "freguesia",
      value: freguesia || "",
    });
  }
  onClickMarker(e, type) {
    if (this.props.onClickMarker) {
      this.props.onClickMarker(e, type);
    } else {
      this.setActivePark(e, type);
    }
  }

  componentDidMount() {
    if (this.Interval === "") this.props.userLocation();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.recenterMap) {
      this.props.recenterEnd();
    }
    if (
      prevProps.growersStatus === "saved" &&
      this.props.growersStatus === "fetched"
    ) {
      this.props.getElementsRelation();
      this.props.getGrowerByParcel();
    }

    if (
      prevProps.selected !== this.props.selected &&
      !this.state.updateElements
    ) {
      this.setState({ updateElements: true });
    }

    if (
      this.mapRef &&
      this.mapRef.current &&
      this.mapRef.current.contextValue &&
      this.mapRef.current.contextValue.map &&
      this.mapRef.current.contextValue.map.zoomControl &&
      this.props.elementActionType === "edit"
    ) {
      this.mapRef.current.contextValue.map.zoomControl.disable();
    } else if (
      this.mapRef &&
      this.mapRef.current &&
      this.mapRef.current.contextValue &&
      this.mapRef.current.contextValue.map &&
      this.mapRef.current.contextValue.map.zoomControl &&
      this.props.elementActionType !== "edit"
    ) {
      this.mapRef.current.contextValue.map.zoomControl.enable();
    }
  }

  componentWillUnmount() {
    this.setState({ overlay: null, initMap: false });
  }

  updateZoom(e) {
    if (
      (e.zoom !== this.props.zoom.zoom && e.center) ||
      (this.props.zoom.center &&
        e.center &&
        this.props.zoom.center.length > 0 &&
        e.center.length > 0 &&
        e.center[0] !== this.props.zoom.center[0] &&
        e.center[1] !== this.props.zoom.center[1]) ||
      !this.props.zoom
    )
      this.props.updateZoom(e);
  }

  stopUserLocation() {
    this.props.toggleTrackUser();
  }

  startUserLocation() {
    this.props.toggleTrackUser();
  }

  getCenter() {
    let lat = "";
    let lng = "";

    // keep the normal way

    // this.props.geoJson.map(park => {
    //   if (park.properties.color === '#01f4c1') getSelection.push(park);
    //   if (park.properties.color === '#4da1ff') getLocal.push(park);
    //   if (park.properties.area) getFreguesia.push(park);
    //   return null;
    // });
    if (this.props.centerToUser) {
      lat = this.props.position.lat;
      lng = this.props.position.lng;
      this.props.centereddOnUser();
    } else if (
      this.props.latestSelection.type === "local" &&
      this.props.geoJson.local &&
      this.props.geoJson.local.length > 0
    ) {
      lat = this.props.geoJson.local[0].geometry.coordinates[0][0][0][1];
      lng = this.props.geoJson.local[0].geometry.coordinates[0][0][0][0];
    } else if (
      this.props.latestSelection.type === "freguesia" &&
      this.props.geoJson.freguesia &&
      this.props.geoJson.freguesia.length > 0
    ) {
      lat = this.props.geoJson.freguesia[0].geometry.coordinates[0][0][0][1];
      lng = this.props.geoJson.freguesia[0].geometry.coordinates[0][0][0][0];
    } else if (
      this.props.latestSelection.type === "selected" &&
      this.props.geoJson.selected &&
      this.props.geoJson.selected.length > 0
    ) {
      if (this.props.geoJson.selected.length >= 1) {
        lat = this.props.geoJson.selected[0].geometry.coordinates[0][0][0][1];
        lng = this.props.geoJson.selected[0].geometry.coordinates[0][0][0][0];
      } else {
        lat = this.props.zoom.center[0];
        lng = this.props.zoom.center[1];
      }
    } else if (
      this.props.geoJson.geoJson.length > 0 &&
      this.props.centerToUser
    ) {
      lat = this.props.geoJson.geoJson[0].geometry.coordinates[0][0][0][1];
      lng = this.props.geoJson.geoJson[0].geometry.coordinates[0][0][0][0];
    } else if (this.props?.zoom?.center) {
      lat = this.props.zoom.center[0];
      lng = this.props.zoom.center[1];
    } else if (this.props.position) {
      lat = this.props.position.lat;
      lng = this.props.position.lng;
    }

    return { lat, lng };
  }

  finishedRemovingElements() {
    if (this.state.updateElements) this.setState({ updateElements: false });
  }

  // setTotalTooltip(element) {
  //   this.setState((prevState, props) => {
  //     return { totalTooltips: [...prevState.totalTooltips, element] };
  //   });
  // }

  // delTotalTooltip() {
  //   this.setState({ totalTooltips: [] });
  // }

  render() {
    const center = this.getCenter();
    const centerTo = this.props.zoom || {};
    // setting defaults
    let mapProps = {
      zoom: centerTo.zoom || 14,
      // zoom: this.props.selected.length > 1 ? 18 : 14,
      center: centerTo.center,
      doubleClickZoom: this.props.elementActionType !== "edit",
      scrollWheelZoom: this.props.elementActionType !== "edit",
      zoomControl: this.props.elementActionType !== "edit",
    };

    // we have a center
    if (center && center.lat !== 0 && center.lng !== 0) {
      mapProps.center = [center.lat, center.lng];
      mapProps.panTo = [center.lat, center.lng];
    }

    //fallback
    if (center && center.lat === "" && center.lng === "") {
      mapProps.center = [38.719637, -9.395666];
      mapProps.panTo = [38.719637, -9.395666];
    }
    // const reload = this.props.status !== 'searchUpdated' || !this.state.initMap;
    return (
      <MapContainer>
        <Map
          className={"map-container"}
          attr={this.props.element && this.props.element.length}
          maxZoom={23}
          ref={this.mapRef}
          // onViewportChanged={(e) => this.updateZoom(e)}
          {...mapProps}
        >
          <Freguesias
            onClickMarker={
              this.props.elementActionType !== "edit" && this.onClickFreguesia
            }
          />
          {this.props.growersStatus !== "getting" &&
            this.props.freguesia &&
            this.props.selected && <Growers data={this.props.growers} />}
          {this.props.geoJsonStatus !== "getting" &&
            this.props.geoJson.geoJson.length >= 1 && (
              <Parks
                key={`${new Date()}-parkswrap`}
                data={this.props.geoJson.geoJson}
                zoom={this.props?.zoom}
                onClickMarker={
                  this.props.elementActionType !== "edit" && this.onClickMarker
                }
                onEachFeature={this.props.onEachFeature}
                reference={this.mapRef}
              />
            )}
          {this.props.status !== "elements_received" &&
          this.props.status !== "searchUpdated" &&
          this.props.zoom?.zoom >= 10 &&
          !this.props.editElements && ( //we will add this in a feature group
              <Elements
                key={`elementswrap`}
                onEachFeature={this.props.onEachElement || this.onEachElement}
                onClickMarker={
                  this.props.elementActionType !== "edit" && this.onClickMarker
                }
                // setTotalTooltip={this.setTotalTooltip}
                // delTotalTooltip={this.delTotalTooltip}
                // totalTooltips={this.state.totalTooltips}
                updateElements={this.state.updateElements}
                finishedRemovingElements={this.finishedRemovingElements}
                data={this.props.elementsGeoJson}
                reference={this.mapRef}
              />
            )}
          {/* <MaskLayer /> */}
          {/* <SearchLayer /> */}
          {this.props.elementActionType === "add" && this.props.selected && (
            <AddElements
              mapRef={this.mapRef}
              onCreated={this.props.onCreated}
            />
          )}
          {this.props.elementActionType === "edit" && (
            <EditElements
              mapRef={this.mapRef}
              elements={this.props.elementsGeoJson}
              elementActionType={this.props.elementActionType}
              updateGeojsonElement={this.props.updateGeojsonElement}
              getElements={this.props.getElements}
              stopUserLocation={this.stopUserLocation}
              startUserLocation={this.startUserLocation}
            />
          )}
          {/* <TileLayer
            maxZoom={22}
            maxNativeZoom={18}
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            attribution="Cascais Ambiente &copy;"
          /> */}
          <TileLayer
            maxZoom={22}
            maxNativeZoom={18}
            url="https://geocascais.cascais.pt:8443/raster/ortos2019/{z}/{x}/{y}.jpg"
            attribution="Cascais Ambiente &copy;"
          />
          {this.props.position.lat !== "" && this.props.position.lng !== "" && (
            <CircleMarker
              center={[this.props.position.lat, this.props.position.lng]}
              {...this.userMarkerParams}
            />
          )}
        </Map>
      </MapContainer>
    );
  }
}

MapComponent.propTypes = {
  position: PropTypes.object.isRequired,
};

MapComponent.defaultProps = {
  position: { lat: "", lng: "" },
};

export default connect(
  (state) => {
    return {
      position: state.user.position,
      mapType: state.app.mapType,
      recenterMap: state.app.recenterMap,
      status: state.app.status,
      centerToUser: state.user.centerToUser,
      zoom: state.app.zoom,
      selected: state.app.search.selected,
      freguesia: state.app.search.freguesia,
      latestSelection: state.app.search.latestSelection,
      element: state.app.search.element,
      growers: state.user.growersList,
      growersStatus: state.user.status,
      geoJsonUpdate: state.parklist.geoJson,
      geoJsonStatus: state.parklist.status,
      geoJson: getGeoJson(state),
      elementsGeoJson: getGeoElementJson(state),
    };
  },
  {
    userLocation,
    updateZoom,
    setActivePark,
    toggleTrackUser,
    centereddOnUser,
    recenterEnd,
    setSearch,
    getGrowerByParcel,
    getElementsRelation,
  }
)(MapComponent);
