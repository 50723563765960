import { Button, ButtonGroup, Chip, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import moment from "moment";
import { IoMdSchool } from "react-icons/io";
import { TbFileCertificate } from "react-icons/tb";

import { store } from "../../store/configure";
import { ButtonIcon, ActionsWrap } from "./styled";
import { modules, growerTypes } from "../../config/general";
import Table from "./Table";
import { BiUserX, BiUserCheck } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";

export default function(props) {
  const [rowsExpanded, setRowsExpanded] = useState([]);
  const [selectedUserParks, setSelectedUserParks] = useState({});
  const processedData = (data) => {
    if (!data) return [];
    return data.map((row) => {
      let userEducations = row.education || [];
      userEducations = userEducations.map((education) => {
        if (education === "0") return "Modulo 1";
        if (education === "1") return "Modulo 2";
        if (education === "2") return "Modulo 3";
        if (education === "3") return "Diploma";
      });

      let userStatus = row.status;
      if (userStatus === 10) {
        userStatus = "Selecionado";
      } else if (userStatus === 7) {
        userStatus = "Atribuido Transferencia";
      } else if (userStatus === 11) {
        userStatus = "Em Formação";
      } else if (userStatus === 9) {
        userStatus = "Suplente";
      } else if (userStatus === 6) {
        userStatus = "Atribuido Pendente";
      }

      return {
        ...row,
        equipa: row.Team ? row.Team.label : "",
        status: userStatus,
        education: userEducations,
      };
    });
  };

  /**
   * viewGrower is the grower that is being previewed
   */
  const viewGrower = (grower) => {
    store.dispatch({ type: "TOGLE_VIEW_GROWER", data: grower });
  };

  /**
   * Dinamic collumns where the buttons will only show up if the user has registered for trainig
   * the option will appear so that the admin change check if the user has finished the training
   */
  let dyanmicTableCollumns = [];
  (props.trainings || [])
    ?.filter((t) => t.status === 1 && t.type === 0)
    .map((trainig) => {
      if (trainig.dates) {
        const tempTableCollumns = trainig.dates.map((train) => {
          return {
            id: `${train.id}_${train.date}`,
            name: `${train.id}_${train.date}`,
            numeric: false,
            disablePadding: false,
            label: `${modules[train.module]}, ${moment(train.date).format(
              "DD/MM/YYYY HH:mm"
            )}`,
            // label: `${trainig.name} ${modules[trainig.module]}, ${moment(
            //   train.date
            // ).format("DD/MM/YYYY HH:mm")}`,
            options: {
              filter: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                const trainingData = tableMeta?.rowData[10] || [];

                let trainingFound = trainingData?.find(
                  (t) => t.TrainingId === trainig.id
                  // && moment(train.date).isSame(moment(t?.date))
                );
                //user registers globaly for training and then we can select each date in the backoffice
                let userTraining = false;
                if (trainingFound) {
                  userTraining = trainingData?.find(
                    (t) =>
                      t.TrainingId === trainig.id &&
                      moment(train.date).isSame(moment(t?.date))
                  );
                }
                //check if this id is deleted

                if (!trainingFound) return;

                //this is to check if the user finished the module
                return (
                  <div style={{ textAlign: "center" }}>
                    {/* <span>
                    {moment(trainingFound?.date).format("DD/MM/YYYY HH:mm")}
                  </span> */}
                    <ActionsWrap width={"auto"}>
                      <ButtonIcon
                        active={userTraining?.status === 3}
                        style={{
                          fontSize: "1.4em",
                          fontWeight: "bold",
                          display: "flex",
                        }}
                        onClick={() =>
                          props.updateUserTraining(
                            trainingFound.id,
                            tableMeta.rowData[0],
                            3,
                            trainig.id,
                            userTraining?.id,
                            train.date
                          )
                        }
                        // active={education?.includes("0")}
                      >
                        <BiUserX />
                      </ButtonIcon>

                      <ButtonIcon
                        style={{
                          fontSize: "1.4em",
                          fontWeight: "bold",
                          display: "flex",
                        }}
                        formed={userTraining?.status === 2}
                        onClick={() =>
                          props.updateUserTraining(
                            trainingFound.id,
                            tableMeta.rowData[0],
                            2,
                            trainig.id,
                            userTraining?.id,
                            train.date
                          )
                        }
                        // active={education?.includes("0")}
                      >
                        <BiUserCheck />
                      </ButtonIcon>
                      <ButtonIcon
                        primary
                        style={{
                          fontSize: "1.4em",
                          fontWeight: "bold",
                          display: "flex",
                        }}
                        formed={userTraining?.status === 0}
                        onClick={() =>
                          props.updateUserTraining(
                            trainingFound.id,
                            tableMeta.rowData[0],
                            4,
                            trainig.id,
                            userTraining?.id,
                            train.date
                          )
                        }
                        // active={education?.includes("0")}
                      >
                        <AiOutlineDelete />
                      </ButtonIcon>
                    </ActionsWrap>
                  </div>
                );
              },
            },
          };
        });

        dyanmicTableCollumns = [...dyanmicTableCollumns, ...tempTableCollumns];
      }
      return null;
    });
  const processedDynamicCollumns = (data) => {
    const columnData = [
      {
        id: "id",
        name: "id",
        numeric: false,
        disablePadding: true,
        label: "id",
        options: {
          display: false,
        },
      },

      {
        id: "educationDone",
        name: "educationDone",
        numeric: false,
        disablePadding: true,
        label: "educationDone",
        options: {
          display: false,
        },
      },

      {
        id: "education",
        name: "education",
        numeric: false,
        disablePadding: true,
        label: "education",
        options: {
          display: false,
        },
      },
      {
        id: "type",
        name: "type",
        numeric: false,
        disablePadding: true,
        label: "Tipo",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <Chip color={"default"} label={growerTypes[value]} />;
          },
        },
      },
      {
        id: "status",
        name: "status",
        numeric: false,
        disablePadding: true,
        label: "status",
        options: {
          display: false,
        },
      },

      {
        id: "attrStatus",
        name: "attrStatus",
        numeric: false,
        disablePadding: true,
        label: "attrStatus",
        options: {
          display: false,
        },
      },
      {
        id: "status",
        name: "status",
        numeric: false,
        disablePadding: false,
        label: "Estado",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value === "Selecionado") {
              return <Chip color={"default"} label={"Selecionado"} />;
            } else if (value === "Atribuido Transferencia") {
              return (
                <Chip color={"secondary"} label={"Atribuido Transferencia"} />
              );
            } else if (value === "Em Formação") {
              return <Chip color={"default"} label={"Em Formação"} />;
            } else if (value === "Suplente") {
              return <Chip color={"primary"} label={"Suplente"} />;
            } else if (value === "Atribuido Pendente") {
              return <Chip color={"default"} label={"Atribuido Pendente"} />;
            }
          },
        },
      },
      {
        id: "name",
        name: "name",
        numeric: false,
        disablePadding: false,
        label: "Nome",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <div
                  onClick={() => viewGrower(tableMeta.rowData[0])}
                  style={{
                    fontWeight: "bold",
                    width: "200px",
                    cursor: "pointer",
                  }}
                >
                  {value}
                </div>
              </>
            );
          },
        },
      },
      {
        id: "freguesia",
        name: "freguesia",
        numeric: false,
        disablePadding: false,
        label: "Freguesia",
      },
      {
        id: "phone",
        name: "phone",
        numeric: false,
        disablePadding: false,
        label: "Telefone",
      },

      {
        id: "training",
        name: "training",
        numeric: false,
        disablePadding: false,
        label: "training",
        options: {
          display: false,
        },
      },
      {
        id: "ranks",
        name: "ranks",
        numeric: false,
        disablePadding: false,
        sortable: false,
        label: "Data",
        options: {
          filter: false,
          sort: false,
          display: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return "";
          },
        },
      },
      ...dyanmicTableCollumns,
      {
        id: "actions",
        name: "Actions",
        numeric: false,
        disablePadding: false,
        label: "Acções",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const education = tableMeta.rowData[2];
            const status = tableMeta.rowData[4];
            const educationDone = tableMeta.rowData[1] || 0;
            return (
              <div>
                <ActionsWrap width={"350px"}>
                  {/* {userEducated && (
                    <Tooltip title={`Atribuir Hortelão`}>
                      <ButtonIcon formed active={Number(userStatus) === 10}>
                        <FaMapMarkerAlt
                          onClick={() => {
                            if (userStatus === 10) return;
                            props.updateUserStatus(10, tableMeta.rowData[0]);
                          }}
                        />
                      </ButtonIcon>
                    </Tooltip>
                  )} */}
                  <ButtonGroup gap={0}>
                    <ButtonIcon
                      style={{
                        fontSize: ".8em",
                        fontWeight: "bold",
                        display: "flex",
                      }}
                      onClick={() =>
                        props.updateUserEducation("0", tableMeta.rowData[0])
                      }
                      active={education?.includes("Modulo 1")}
                    >
                      Modulo 1
                    </ButtonIcon>
                    <ButtonIcon
                      active={education?.includes("Modulo 2")}
                      style={{
                        fontSize: ".8em",
                        fontWeight: "bold",
                        display: "flex",
                      }}
                      onClick={() =>
                        props.updateUserEducation("1", tableMeta.rowData[0])
                      }
                    >
                      Modulo 2
                    </ButtonIcon>

                    <ButtonIcon
                      style={{
                        fontSize: ".8em",
                        fontWeight: "bold",
                        display: "flex",
                      }}
                      active={education?.includes("Modulo 3")}
                      onClick={() =>
                        props.updateUserEducation("2", tableMeta.rowData[0])
                      }
                    >
                      Modulo 3
                    </ButtonIcon>
                  </ButtonGroup>
                  <ButtonIcon
                    primary={educationDone}
                    active={status === 9}
                    style={{ fontSize: "1.2em" }}
                    onClick={() =>
                      props.updateUserEducation(
                        "finished",
                        tableMeta.rowData[0]
                      )
                    }
                  >
                    <IoMdSchool />
                  </ButtonIcon>
                  <ButtonIcon
                    // primary={educationDone}
                    // active={status === 9}
                    primary
                    style={{ fontSize: "1.2em" }}
                    active={education?.includes("Diploma")}
                    onClick={() =>
                      props.updateUserEducation("3", tableMeta.rowData[0])
                    }
                  >
                    <TbFileCertificate />{" "}
                  </ButtonIcon>
                </ActionsWrap>
              </div>
            );
          },
        },
      },
      {
        id: "email",
        name: "email",
        numeric: false,
        disablePadding: false,
        label: "E-mail",
        options: {
          display: false,
        },
      },
    ];

    /**
     * dynamic park collumns
     */
    let costumParks = [];

    data?.map((row) => {
      if (row?.ranks) {
        costumParks = [...costumParks, ...Object.keys(row?.ranks)];
      }
    });

    const parks = props.parks;
    const uniqueParks = [...new Set(costumParks)]
      .sort()
      .reverse()
      .map((park) => {
        const thisPark = parks.find((p) => p.numero === park);
        const ParkQuotas = props?.quotas[park] || {};
        if (!thisPark) return null;

        columnData.push({
          id: `Bairro ${thisPark.número_ant}`,
          name: `Bairro ${thisPark.número_ant}`,
          numeric: true,
          disablePadding: false,
          label: `${ParkQuotas.ranking0 ? `${ParkQuotas.ranking0}|` : ""}Bair ${
            thisPark.número_ant
          }`,
          options: {
            filter: true,
            sortCompare: (order) => {
              return (obj1, obj2) => {
                const ranks1Val = obj1.rowData[11];
                const ranks2Val = obj2.rowData[11];
                const val1 = ranks1Val[park]?.ranking1?.row_number;
                const val2 = ranks2Val[park]?.ranking1?.row_number;
                return (
                  (Number(val1) - Number(val2) || 10000) *
                  (order === "asc" ? 1 : -1)
                );
              };
            },
            customBodyRender: (value, tableMeta, updateValue) => {
              const ranksVal = tableMeta.rowData[11];
              //check if park is in user selectedParks
              const isSelected = selectedUserParks[
                tableMeta.rowData[0]
              ]?.includes(park);

              return ranksVal[park]?.ranking1?.row_number ? (
                <Chip
                  color={isSelected ? "primary" : "default"}
                  label={ranksVal[park]?.ranking1?.row_number}
                />
              ) : (
                "-"
              );
            },
          },
        });
        columnData.push({
          id: `Proximidade ${thisPark.número_ant}`,
          name: `Proximidade ${thisPark.número_ant}`,
          numeric: false,
          disablePadding: false,
          label: `${
            ParkQuotas.ranking1 ? `${ParkQuotas.ranking1}|` : ""
          } Prox ${thisPark.número_ant}`,
          options: {
            sort: true,
            sortCompare: (order) => {
              return (obj1, obj2) => {
                const ranks1Val = obj1.rowData[11];
                const ranks2Val = obj2.rowData[11];
                const val1 = ranks1Val[park]?.ranking2?.row_number;
                const val2 = ranks2Val[park]?.ranking2?.row_number;
                return (
                  (Number(val1) - Number(val2) || 10000) *
                  (order === "asc" ? 1 : -1)
                );
              };
            },
            // hint: "Sort by amount of hobbies",
            customBodyRender: (value, tableMeta, updateValue) => {
              const ranksVal = tableMeta.rowData[11];
              const isSelected = selectedUserParks[
                tableMeta.rowData[0]
              ]?.includes(park);
              return ranksVal[park]?.ranking2?.row_number ? (
                <Chip
                  color={isSelected ? "primary" : "default"}
                  label={ranksVal[park]?.ranking2?.row_number}
                />
              ) : (
                "-"
              );
            },
          },
        });
        columnData.push({
          id: `Freguesia ${thisPark.número_ant}`,
          name: `Freguesia ${thisPark.número_ant}`,
          numeric: false,
          disablePadding: false,
          label: `${ParkQuotas.ranking2 ? `${ParkQuotas.ranking2}|` : ""}Freg ${
            thisPark.número_ant
          }`,
          options: {
            sort: true,
            sortCompare: (order) => {
              return (obj1, obj2) => {
                const ranks1Val = obj1.rowData[11];
                const ranks2Val = obj2.rowData[11];
                const val1 = ranks1Val[park]?.rankingFreguesia?.row_number;
                const val2 = ranks2Val[park]?.rankingFreguesia?.row_number;
                return (
                  (Number(val1) - Number(val2) || 10000) *
                  (order === "asc" ? 1 : -1)
                );
              };
            },
            customBodyRender: (value, tableMeta, updateValue) => {
              const ranksVal = tableMeta.rowData[11];
              const isSelected = selectedUserParks[
                tableMeta.rowData[0]
              ]?.includes(park);
              return ranksVal[park]?.rankingFreguesia?.row_number ? (
                <Chip
                  color={isSelected ? "primary" : "default"}
                  label={ranksVal[park]?.rankingFreguesia?.row_number}
                />
              ) : (
                "-"
              );
            },
          },
        });
        return park;
      });

    return columnData;
  };

  return (
    <Table
      orderBy={"id"}
      data={processedData(props.data)}
      rowsExpanded={rowsExpanded}
      setRowsExpanded={setRowsExpanded}
      selectable={true}
      selectCustomAction={props.selectCustomAction}
      // click={props.click}
      // renderExpandableRow={(rowData, rowMeta) => {
      //   const thisData = dataReady.find((d) => d.id === rowData[0]);
      //   return (
      //     <tr style={{ width: "100%" }}>
      //       <td style={{ width: "100%" }} colSpan={"20"}>
      //         <Edit
      //           noModal={true}
      //           edit={thisData}
      //           index={rowMeta.rowIndex}
      //           rowsExpanded={rowsExpanded}
      //           reloadData={props.reloadData}
      //           setRowsExpanded={setRowsExpanded}
      //         />
      //       </td>
      //     </tr>
      //   );
      // }}
      header={processedDynamicCollumns(props.data)}
    />
  );
}
